<script>
import Logo from '@components/Logo';

export default {
	name: 'Login',
	components: {
		Logo,
	},
}
</script>

<template>
	<div class="page login">
		<header class="header">
			<Logo maxwidth="235px" />
		</header>

		<div class="section">
			<router-view
				:key="$route.path"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@styles/_variables";

::v-deep.login h2.title {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 1.4rem;
}

.page.login {
	background-color: $gray-20;
	background-color: var(--gray-20);
}

.header {
	height: 86px;
	background-color: $white;
	border-top: 6px solid $light-green;
	background-color: var(--white);
	border-top: 6px solid mix($primary, $white, 50%);
	border-top: 6px solid var(--light-green);
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
